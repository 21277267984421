import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

import { Avatar } from '../components/common/meta/Icon'

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor
    const posts = data.allGhostPost.edges
    const twitterUrl = author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <Layout>

                <section className="outer">
                    <div className="inner posts">

                        <header className="author-profile">

                            <div className="author-profile-content">

                                {!!author.profile_image ?
                                    <img className="author-profile-pic" src={author.profile_image} alt={author.name} />
                                :
                                    <span className="author-profile-pic"><Avatar /></span>
                                }

                                <h1>{author.name}</h1>

                                {!!author.bio &&
                                    <p>
                                        {author.bio}
                                    </p>
                                }

                                <div className="author-profile-meta">
                                    {!!author.location && <div className="author-profile-location">📍 {author.location}</div>}
                                    {!!author.website && <span><a className="author-profile-social-link" href={author.website} target="_blank" rel="noopener">{author.website}</a></span> }
                                    {!!author.twitter && <span><a className="author-profile-social-link" href={author.twitter_url} target="_blank" rel="noopener">{author.twitter_url}</a></span> }
                                    {!!author.facebook && <span><a className="author-profile-social-link" href={author.facebook_url} target="_blank" rel="noopener">{author.facebook_url}</a></span> }
                                </div>

                            </div>
                        </header>

                        <div className="post-feed">
                            {posts.map(({ node }, index) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} index={index} home={false} />
                            ))}
                        </div>

                        <Pagination pageContext={pageContext} />
                        
                    </div>
                </section>

            </Layout>
        </>
    )
}

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {authors: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
